/**
 *
 *
 *
 */
import React from 'react';
import ReactDOM from 'react-dom/client';


/**
 *
 *
 *
 */
const perv = console.error;
console.error = (...args) => {
  const msg = args[0];
  const pattern = 'Support for defaultProps will be removed';
  if (typeof msg === 'string' && msg.includes(pattern)) return;
  perv.apply(console, args);
};


/**
 *
 *
 *
 */
const { default: App } = require(`./${process.env.REACT_APP_NAME}`);
// const { default: App } = require('./_stories/_index');


/**
 *
 *
 *
 */
const root = ReactDOM.createRoot(document.getElementById('root'));


/**
 *
 *
 *
 */
// root.render(<React.StrictMode><App /></React.StrictMode>);
root.render(<App />);