/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
const MESSAGE = `
  Maximize the efficiency of your lab operations with our
  customizable and adaptable LIMS. Automate workflows to
  handle more samples, accelerate report generation, and
  propel the growth of your laboratory.
`;


/**
 *
 *
 *
 */
export default function Home() {

  return (
    <S.Container>
      <S.InnerContainer>
        <T.IX7 $c={'rgb(241, 109, 84)'}>PRODUCTS</T.IX7>
        <div style={{ height: 20 }} />
        <T.IE7>Laboratory Information Management System (LIMS)</T.IE7>
        <div style={{ height: 20 }} />
        <T.IL4 $c={'rgb(120, 120, 120)'} style={{ paddingRight: 50 }}>
          {MESSAGE}
        </T.IL4>
      </S.InnerContainer>
      <S.ImageContainer>
        <S.Image src="/assets/tool.png" />
      </S.ImageContainer>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  margin-top: -10px;
  height: 400px;
  @media (max-width: 1200px) {
    height: 300px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

S.InnerContainer = styled.div`
  flex: 1;
  order: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    order: 2;
  }
`;

S.ImageContainer = styled.div`
  flex: 1;
  order: 2;
  @media (max-width: 600px) {
    order: 1;
    border-radius: 6px;
  }
`;

S.Image = styled.img`
  height: 100%;
  width: auto;
`;
