/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { GlobeStyle, Theme } from '../_components/Style';
import Analytics from '../_components/Analytics';
import NotFound from '../_components/NotFound';
import Header from './components/Header';
import Home from './pages/Home';


/**
 *
 *
 *
 */
export default function Www() {

  return (
    <ThemeProvider theme={Theme.light}>
      <Router.BrowserRouter basename="/">
        <GlobeStyle />
        <Header />
        <Analytics />
        <S.PageContainer>
          <Router.Routes>
            <Router.Route path='/' element={<Home />} />
            <Router.Route path="*" element={<NotFound />} />
          </Router.Routes>
        </S.PageContainer>
      </Router.BrowserRouter>
    </ThemeProvider>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: auto;
  height: 100vh;
  @media (max-width: 600px) {
    padding-top: 50px;
  }
  @media (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
