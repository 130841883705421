/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import * as u from '../utils';


/**
 *
 *
 *
 */
export default function Analytics() {

  const location = Router.useLocation();
  React.useEffect(() => { onChange(); }, [location]);
  return null;

  /**
   *
   *
   *
   */
  async function onChange() {
    window.scrollTo(0, 0);
    const url = location.pathname;
    const items = [{ event_name: 'page_view', data: { url } }];
    const query = 'mutation AnalyticsSubmitEvents($items: JSON!) { analytics_submit_events(items: $items) }';
    await u.fetcher(query, { items });
  }
}
