/**
 *
 *
 *
 */
import styled from 'styled-components';


/**
 *
 *
 *
 */
const _ = styled.p`
  color: ${p => {
    if (p.$c) return p.$c;
    if (p.color) return p.color;
    return 'rgba(36, 41, 47, 1)';
  }};
  ${p => p.$isBold && 'font-weight: bold !important;'}
  -webkit-font-smoothing: antialiased;
  font-style: ${p => p.$isItalic ? 'italic' : 'normal'};
  // white-space: pre-wrap;
`;


/**
 *
 *
 *
 */
const FAMILY = [['Inter', 'I'], ['Courier New', 'C'], ['Google Sans', 'G'], ['-apple-system, "system-ui", "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif', 'S']];
const SIZE = [[6, 'N'], [8, 'S'], [10, 'SX'], [12, 'M'], [14, 'L'], [16, 'X'], [18, 'O'], [22, 'H'], [24, 'P'], [30, 'G'], [45, 'E'], [55, 'F'], [65, 'J']];
const WEIGHTS = [[300, '3'], [400, '4'], [500, '5'], [600, '6'], [700, '7']];


/**
 *  Usage e.g. T.IM5:
 *    - font family FIRST
 *    - font size   SECOND
 *    - font weight THIRD
 */
const MATRIX = SIZE.map(size => FAMILY.map(fam => WEIGHTS.map(weight => {
  return { size, fam, weight };
}))).flat(Infinity).reduce((acc, elm) => {
  const key = elm['fam'][1] + elm['size'][1] + elm['weight'][1];
  acc[key] = styled(_)`
    font-size: ${elm['size'][0]}px;
    font-weight: ${elm['weight'][0]};
    font-family: ${elm['fam'][0]};
  `;
  return acc;
}, {});


/**
 *
 *
 *
 */
export default { ...MATRIX };
