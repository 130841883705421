/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
export default function Header() {

  React.useEffect(() => { document.title = 'Parvion'; }, []);

  return (
    <S.Container id="header">
      <S.InnerContainer>
        <S.ItemLinkContainer href="/">
          <S.Image src="/assets/parvion.png" />
        </S.ItemLinkContainer>
        <S.ItemContainer style={{ marginLeft: 'auto', paddingRight: 0 }}>
          <S.ButtonLogin href="https://auth.parvion.uk">
            <T.IX5 $c={'rgb(241, 109, 84)'}>Login</T.IX5>
          </S.ButtonLogin>
        </S.ItemContainer>
      </S.InnerContainer>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
var S = {};

S.Container = styled.div`
  top: 0px;
  position: fixed;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  background-color: white;
  @media print { display: none; }
  z-index: 10;
  @media (max-width: 1200px) {
    height: 50px;
    padding: 0px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  border-bottom: rgb(237, 239, 244);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

S.ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
`;

S.ItemLinkContainer = styled.a`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
  :hover { cursor: pointer; }
`;

S.Image = styled.img`
  width: 100px;
`;

S.LoginButton = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
`;

S.ButtonLogin = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid rgb(241, 109, 84);
  border-radius: 5px;
  height: 35px;
  padding: 0px 20px;
  &:hover { cursor: pointer; background-color: rgb(237, 239, 244); }
`;
