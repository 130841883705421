/**
 *
 *
 *
 */
import * as d3 from 'd3';
import { DateTime } from 'luxon';
import * as R from 'ramda';


/**
 *
 *
 *
 */
export const fetcher = async (source, variableValues = {}) => {
  try {
    const isDev = process.env.NODE_ENV === 'development';
    const currPath = isDev ? `http://api.parvion.localhost:3015` : `https://api.parvion.uk`;
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    const opts = { method: 'POST', headers, body: JSON.stringify({ source, variableValues }) };
    opts.credentials = 'include';
    const res = await fetch(`${currPath}/g`, opts);
    if (!res.ok) throw new Error(res.status);
    const data = await res.json();
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};


/**
 *
 *
 *
 */
export const toggle = (arr=[], val) => {
  const idx = arr.indexOf(val);
  if (idx === -1) return [...arr, val];
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};


/**
 *
 *
 *
 */
export const sleep = (ms) => {
  return new Promise(done => {
    setTimeout(done, ms);
  });
};


/**
 *
 *
 *
 */
export const genStr = (n=14) => {
  const _s = 'abcdefghijklmnopqrstuvwxyz';
  return [...Array(n)].map(() => {
    return _s[Math.floor(Math.random() * _s.length)]
  }).join('');
}



/**
 *
 *
 *
 */
export const slider = (range, domain, stepSize) => {

  const scale = d3.scaleLinear()
    .domain(domain)
    .range(range);

  const valueSteps = d3.range(domain[0], domain[1], stepSize);
  const rangeSteps = valueSteps.map(scale);

  return {
    pixToSnap: (pix) => {
      const idx = d3.bisectLeft(rangeSteps, pix);
      if (idx === 0) return rangeSteps[0];
      if (idx === rangeSteps.length) return rangeSteps[rangeSteps.length - 1];
      const prev = rangeSteps[idx - 1];
      const next = rangeSteps[idx];
      return (pix - prev) < (next - pix) ? prev : next;
    },
    toRange: (val) => {
      const idx = valueSteps.indexOf(val);
      return rangeSteps[idx];
    },
    toValue: (pix) => {
      const idx = rangeSteps.indexOf(pix);
      return valueSteps[idx];
    },
  };
};


/**
 *
 *
 *
 */
export const onTraverse = (obj, callback, path = []) => {
  const maybeStop = callback(obj, path);
  if (maybeStop) return;
  if (obj === null || obj === undefined) return;
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => onTraverse(item, callback, path.concat(index)));
  } else if (typeof obj === 'object') {
    Object.keys(obj).forEach(key => onTraverse(obj[key], callback, path.concat(key)));
  }
};


/**
 *
 *
 *
 */
export const onSetObjectByPath = (path, value, obj) => {
  const createNestedObj = (path, val) => {
    if (path.length === 1) {
      const key = path[0];
      return typeof key === 'number' ? [val] : { [key]: val };
    }
    const key = path[0];
    return typeof key === 'number'
      ? [].concat(createNestedObj(path.slice(1), val))
      : { [key]: createNestedObj(path.slice(1), val) };
  };

  const nestedObj = createNestedObj(path, value);

  const isObjectVal = R.is(Object, R.path(path, obj)) && R.is(Object, value);
  return isObjectVal
    ? R.over(R.lensPath(path), R.mergeDeepRight(R.__, value), obj)
    : R.mergeDeepRight(obj, nestedObj);
};


/**
 *
 *
 *
 */
export const onTableDeepCopy = (tbl, schemaName) => {
  return Object.entries(tbl.columns).reduce((acc, [_, elm]) => {
    acc += `\t`;
    acc += (`${elm.name} - ${elm.type} ${elm.char_length ?? ''}`).trim();
    acc += `\n`;
    return acc;
  }, `Table "${schemaName}"."${tbl.name}":\n`);
};


/**
 *
 *
 *
 */
export const byName = (a, b) => {
  // Move 'id' to the top
  if (a.name === 'id') return -1;
  if (b.name === 'id') return 1;

  // Move 'updated_at' to the very bottom
  if (a.name === 'updated_at') return 1;
  if (b.name === 'updated_at') return -1;

  // Move 'created_at' toward the bottom, but above 'updated_at'
  if (a.name === 'created_at') return 1;
  if (b.name === 'created_at') return -1;

  // Move anything containing 'id' nearer to the top
  if (a.name.includes('id')) return -1;
  if (b.name.includes('id')) return 1;

  // Otherwise, maintain existing order
  return 0;
};


/**
 *
 *
 *
 */
export const debounce = (func, delay) => {

  let timer = undefined;

  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};


/**
 *
 *
 *
 */
export const trunc = (str, num) => {
  if (!str || str.length <= num || typeof str !== 'string') return str;
  const shortStr = str.slice(0, num);
  const lCommaIndex = shortStr.lastIndexOf(',');
  return num - lCommaIndex > 5 ? shortStr + '...' : str.slice(0, lCommaIndex) + '...';
};


/**
 *
 *
 *
 */
export const onTimeAgo = (time) => {
  const now = DateTime.local();
  const givenTime = (typeof time === 'number' ? DateTime.fromSeconds(time, { zone: 'utc' }) : DateTime.fromISO(time, { zone: 'utc' })).setZone('Europe/London');
  const diff = now.diff(givenTime, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']);

  if (diff.years > 0 || diff.months > 0) {
    return givenTime.toFormat('MMM dd');
  } else if (diff.days > 6) {
    return givenTime.toFormat('MMM dd');
  } else if (diff.days > 1) {
    return givenTime.toFormat('EEEE');
  } else if (diff.days === 1) {
    return 'Yesterday';
  } else if (diff.hours > 0) {
    return `${diff.hours} ${diff.hours === 1 ? 'hr' : 'hrs'} ago`;
  } else if (diff.minutes > 0) {
    return `${diff.minutes} ${diff.minutes === 1 ? 'min' : 'mins'} ago`;
  } else {
    return 'Just now';
  }
};


/**
 *
 *
 *
 */
export const onCap = str => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


/**
 *  Example:
 *
 *  [
 *    { "name": "id", "type": "integer" },
 *    { "name": "description", "type": "text" },
 *    { "name": "name", "type": "varchar(150)" },
 *    { "name": "created_at", "type": "timestamp with time zone" }
 *  ]
 *
 */
export const onGenerateFakeData = (props) => {
  return props.reduce((acc, elm) => {

    const constraints = elm.constraints ?? {};
    const qualifiers = elm.qualifiers ?? {};
    const $length = Number(qualifiers.$length ?? 0);
    const isNotNull = !!constraints.not_null;
    const isPrimaryKey = !!constraints.primary_key;
    const maybeNull = isNotNull ? false : faker.datatype.boolean(0.2);
    const $default = constraints?.$default?.expression ?? '';
    const isSerial = $default.includes('nextval');
    if (maybeNull) { acc[elm.name] = null; return acc; }
    if (isSerial && isPrimaryKey) console.warn(`Serial & PrimaryKey`);

    switch (elm.type) {
      case 'integer':
        acc[elm.name] = faker.number.int(1000);
        return acc;
      case 'smallint':
        acc[elm.name] = faker.number.int(100);
        return acc;
      case 'text':
        acc[elm.name] = faker.lorem.paragraph();
        return acc;
      case 'varchar':
        acc[elm.name] = faker.string.alphanumeric($length);
        return acc;
      case 'character varying':
        acc[elm.name] = faker.string.alphanumeric($length);
        return acc;
      case 'jsonb':
        acc[elm.name] = JSON.stringify({ yesOrNo: faker.datatype.boolean(), foo: faker.lorem.word() });
        return acc;
      case 'timestamp with time zone':
        acc[elm.name] = faker.date.recent().toISOString();
        return acc;
      case 'boolean':
        acc[elm.name] = faker.datatype.boolean();
        return acc;
      case 'date':
        acc[elm.name] = faker.date.recent().toISOString().split('T')[0];
        return acc;
      case 'bytea':
        acc[elm.name] = btoa(faker.string.alphanumeric(10));
        return acc;
      default:
        throw new Error(`Unsupported type: ${elm.type}`);
    }
  }, {});
};


/**
 *
 *
 *
 */
export const onAge = (dob) => {

  if (!dob) return '-';

  const birth = DateTime.fromISO(dob);
  const now = DateTime.now();
  if (!birth.isValid) return '-';
  const years = now.year - birth.year;
  const months = now.month - birth.month;

  if (years === 0) return `${months} month${months !== 1 ? 's' : ''}`;
  if (years === 1) return `${years} year, ${months} month${months !== 1 ? 's' : ''}`;
  return `${years} years`;
}
